<template>
  <div id="search-classify">
      <el-input placeholder="请输入内容" v-model="input" clearable style="width: 300px;"> </el-input>
      <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
  </div>
</template>

<script>
export default {
  name: 'SearchReplay',
  props: ['inputButton'],
  data() {
    return {
      input: ''
    };
  },
  methods: {
    search(){
      if (this.input.length===0){
        this.$message({
          type: 'error',
          message: '请输入信息'
        });
      } else {
        this.inputButton(this.input);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#search-classify {
  display: inline-block;
  margin: 0 10px;
}
</style>
