<template>
  <el-form ref="form" label-width="80px">
  <el-form-item label="信件内容">
    <el-input type="textarea" v-model="message.message" disabled></el-input>
  </el-form-item>
  <el-form-item label="我的回复">
    <el-input type="textarea" v-model="message.replyContent" disabled></el-input>
  </el-form-item>
  <el-form-item>
    <el-button type="warning" @click="cancelBtn">取消</el-button>
  </el-form-item>
</el-form>
</template>

<script>
export default {
  name: 'ViewForm',
  props: ['cancel', 'modifychangeMsg'],
  data() {
    return {
      message: ''
    };
  },
  mounted() {
    this.message = this.modifychangeMsg;
  },
  methods: {
    cancelBtn(){
      this.cancel();
    }
  }
};
</script>

<style>

</style>