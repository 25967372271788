<template>
  <el-button type="warning" icon="el-icon-edit" @click="open">修改</el-button>
</template>

<script>
import {updateReply} from '../../service/getData';
export default {
  name: 'ModifyReplay',
  props: ['modifyOne', 'modifychangeMsg'],
  methods: {
    open() {
      this.$prompt('请输入修改的内容', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: this.modifychangeMsg.replyContent
      }).then(({ value }) => {
        updateReply(this.modifychangeMsg.replyId, value).then(values=>{
          console.log(values);
          if (values.code === 200){
            this.modifyOne();//修改成功，父组件重新遍历数据
            this.$message({
              type: 'success',
              message: '修改成功!'
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消修改'
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>

</style>