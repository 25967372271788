<template>
  <div id="view-replay">
    <el-button type="primary" icon="el-icon-edit" @click="open">查看</el-button>
    <div v-if="isForm" class="form-message">
      <div class="form-content">
        <ViewContentForm :cancel="cancelBtn" :modifychangeMsg="modifychangeMsg"/>
      </div>
    </div>
  </div>
</template>

<script>
import ViewContentForm from './ViewContentForm.vue';
export default {
  name: 'ViewReplay',
  props: ['modifychangeMsg'],
  data(){
    return {
      isForm: false
    };
  },
  components: {
    ViewContentForm
  },
  methods: {
    open(){
      this.isForm = true;
    },
    cancelBtn(){
      this.isForm = false;
    },
    replaySuccess(){
      this.replayOne();
    }
  }
};
</script>

<style lang="scss" scoped>
#view-replay {
  display: inline-block;
  margin: 0 5px;
  .form-message {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0 0 0 / 30%);
    .form-content {
      position: absolute;
      top: 50%;
      left: 50%;
      overflow: auto;
      padding: 10px;

      // width: 400px;
      width: 100%;
      max-width: 100%;

      // height: 500px;
      height: 100%;
      max-height: 100%;
      border-radius: 5px;
      background-color: #ffffff;
      transform: translate(-50%, -50%);
    }
  }
}
</style>