<template>
  <div id="classify-management">
    <BatchDeleteMyReplay :batchDeleteId="multipleSelection" :deleteMuch="deleteMuchMsg"/>
    <el-button type="info" icon="el-icon-s-tools" @click="reset">重置</el-button>
    <SearchReplay :inputButton="SearchMsg"/>
    <el-table :data="tableData" style="width: 100%;" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="replyContent" label="回复内容" width="180">
        <template slot-scope="scope">
          <div :title="scope.row.replyContent">
            {{num(scope.row.replyContent,12)}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="message" label="信件内容" width="170">
        <template slot-scope="scope">
          <div :title="scope.row.message">
            {{num(scope.row.message,12)}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="回复时间"> </el-table-column>
      <el-table-column prop="address" label="操作">
        <template slot-scope="scope">
          <ViewContent :modifychangeMsg="scope.row"/>
          <DeleteReplay :index="tableData.length" :deleteChangeMsg="scope.row" :deleteOne="deleteOneMsg"/>
          <ModifyReplay :modifychangeMsg="scope.row" :modifyOne="modifyOneMsg"/>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      background
      :layout="layout"
      :total="total"
      :page-sizes="page_sizes"
      :page-size="page_size"
      :current-page="currentPage">
    </el-pagination>
  </div>
</template>

<script>
import {pages} from '../../mixins/pages';
import {findAllReply, findReplyByNameLike} from '../../service/getData';
import DeleteReplay from '../../pages/MyReplay/DeleteReplay.vue';
import ModifyReplay from '../../pages/MyReplay/ModifyReplay.vue';
import BatchDeleteMyReplay from '../../pages/MyReplay/BatchDeleteMyReplay.vue';
import SearchReplay from '../../pages/MyReplay/SearchReplay.vue';
import ViewContent from '../../pages/MailboxManagement/ViewContent.vue';
export default {
  name: 'ClassifyManagement',
  mixins: [pages],
  data() {
    return {
      multipleSelection: [], // 多选框哪几个被选中
      tableData: [], // 分页遍历数据
      input: ''//输入框
    };
  },
  components: {
    DeleteReplay,
    ModifyReplay,
    BatchDeleteMyReplay,
    SearchReplay,
    ViewContent
  },
  computed: {
    num(){
      return (chinese, index = 5)=>{
        if (chinese.length<=index){
          return chinese;
        } else {
          return chinese.substring(0, index - 2)+'...';
        }
      };
    }
  },
  watch: {
    page_size_val(){
      this._findAllType(this.currentPage_val, this.page_size_val);
    },
    currentPage_val(){
      this._findAllType(this.currentPage_val, this.page_size_val);
    }
  },
  mounted(){
    // 第一次遍历信息
    this._findAllType();
  },
  methods: {
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    addMsg(){//后台添加问题类别后重新遍历
      this._findAllType(this.currentPage_val, this.page_size_val);
    },
    deleteOneMsg(){//后台单个删除问题类别后重新遍历
      if (this.tableData.length===1){
        this._findAllType(this.currentPage_val-1, this.page_size_val);
      } else {
        this._findAllType(this.currentPage_val, this.page_size_val);
      }
    },
    deleteMuchMsg(){//后台批量删除问题类别后重新遍历
      console.log(this.tableData.length===this.multipleSelection.length);
      if (this.tableData.length===this.multipleSelection.length){
        this._findAllType(this.currentPage_val-1, this.page_size_val);
      } else {
        this._findAllType(this.currentPage_val, this.page_size_val);
      }
      this.multipleSelection = [];//讲批量选择清空
    },
    modifyOneMsg(){//后台修改问题类别
      this._findAllType(this.currentPage_val, this.page_size_val);
    },
    SearchMsg(values){//模糊搜索问题类别
      this.input = values;
      this._findAllType(1, this.page_size_val);
    },
    reset(){//重置
      this.input = '';
      this._findAllType(1, this.page_size_val);
    },
    _findAllType(pageNow, pageSize){//后台遍历不同问题类别，如果this.input有内容则模糊查询，如果没有内容则正常分页
      if (this.input.length===0){
        findAllReply(pageNow, pageSize).then(values=>{
          console.log('我的回复', values);
          if (values.code === 200){
            this.total = values.object.total;
            this.tableData = values.object.list;
          }
        });
      } else {
        findReplyByNameLike(this.input, pageNow, pageSize).then(values=>{
          if (values.code === 200){
            this.total = values.object.total;
            this.tableData = values.object.list;
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
